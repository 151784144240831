.add-msg {
    background-color: #6d76f7;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 10px;
}

.add-msg h2{
    margin-bottom: 20px;
}

.add-msg button{
    font-size: 17px;
    text-align: center;
    color: white;
    background-color: #303132;
    border: 0px;
    border-radius: 33px;
    padding: 10px 20px;
    width: 40%;
    min-width: 200px;
    transition: all .3s;
    cursor: pointer;
}

.add-msg button:hover{
    background-color: #242425;
}

.add-msg-input {
    background-size: 12px;
    background-position: 15px center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 33px;
    padding: 10px 5px 10px 20px;
    text-indent: 22px;
    width: 80%;
    min-width: 200px;
}

.echange-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    width: 70%;
    margin: 40px auto;
    padding: 30px;
}

.echange-action-technicien {
    background-color: coral;
    align-self: flex-start;
    width: 40%;
    border-radius: 10px;
    padding: 20px;
}

.echange-action-client {
    background-color: lightblue;
    align-self: flex-end;
    width: 40%;
    border-radius: 10px;
    padding: 20px;
}