.navBar{
    background: rgb(90, 85, 194);
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navBar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 5px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover{
    background-color: #6d76f7;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars, .fa-times{
    color: #fff;
    font-size: 2rem;
}

.fa-solid{
    margin-right: 8px;
}

.menu-icon{
    display: none;
}

@media screen and (max-width: 960px) {
    .navBar{
        position: relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 400px;
        position: absolute;
        top: 120px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: #4a4bac;
        left: 0;
        opacity: 1;
        transition: all 0.5 ease;
        z-index: 1;
    }

    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        background-color: #7577fa;
        border-radius: 0;
    }

    .navBar-logo{
        position: absolute;
        top: 0;
        left: 0;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 20px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
}