.page-erreur{
    width: 100%;
    height: 100vh;
    background-color: #6d76f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.page-erreur h1{
    font-size: 6em;
    text-align: center;
}

.page-erreur p{
    font-size: 1.5em;
}

.page-erreur button{
    width: 10%;
    min-width: 150px;
    height: 80px;
    background-color: #fff;
    border: none;
    border-radius: 33px;
    font-size: 1.8em;
    font-weight: bold;
    transition: 0.3s ease-out;
    cursor: pointer;
}

.page-erreur button:hover{
    background-color: greenyellow;
}