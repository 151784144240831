.page-container{
    display: flex;
    gap: 30px;
    margin: 20px;
}

.tickets-container{
    flex: 4;
    margin: 40px auto 20px auto;
    width: 95%;
    padding: 20px;
    box-shadow: 0px 1px 5px 2px rgba (0,0,0,0.75);
    -webkit-box-shadow: 0px 1px 5px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 5px 2px rgba(0,0,0,0.75);
}

.tickets-infos{
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: rgb(167, 164, 164);
}

.tickets{
    margin-top: 40px;
    border-collapse: collapse;
    width: 100%;
}

.tickets td, th{
    padding: 18px;
}

.tickets tbody:nth-child(even){
    background-color:#f2f2f2;
}

.tickets tbody{
    transition: 0.2s all;
}

.my-ticket:hover {
    background-color: #ddd;
    cursor: pointer;
}

.tickets tbody:nth-child(1):hover {
    cursor: initial;
}

.tickets th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #6d76f7;
    color: white;
}

.ferme{
    background-color: #2bd52b;
}

.ouvert{
    background-color: rgb(239, 85, 85);
}

.paliatif{
    background-color: orange;
}

.repare{
    background-color: #5d65ce;
}

.en-cours{
    background-color: pink;
}

.pagination{
    display: flex;
    justify-content: center;
    padding: 15px;
    margin-top: 10px;
    font-size: 1.1em;
    gap: 10px;
}

.page-link{
    color: #6d76f7;
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.page-link:hover{
    background-color: #e4e1e1;
}

.active .page-link{
    background-color: #6d76f7;
    color: #fff;
    cursor: default;
}

.active:hover .page-link{
    background-color: #6d76f7;
    color: #fff;
    cursor: default;
}

.toggle-filtre{
    display: flex;
    gap: 10px;
}

/* ##### Bouton filtre ##### */
input[type="checkbox"].toggle-filtre-button {
    display: none;
  }
  input[type="checkbox"].toggle-filtre-button + label {
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    padding:2px;
    background-color: #c0ceda ;
    transition: all 0.2s ;
  }
  input[type="checkbox"].toggle-filtre-button + label::before {
    box-sizing: border-box;
    display: block;
    content: "";
    height: calc(1.5rem - 4px);
    width: calc(1.5rem - 4px);
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.2s ;
  }
  input[type="checkbox"].toggle-filtre-button:checked + label {
    background-color: #00B7E8 ;
  }
  input[type="checkbox"].toggle-filtre-button:checked + label::before {
    margin-left: 1.5rem ;
  }
/* ##### Fin Bouton filtre ##### */
.btn-sort-container {
    margin: 8px 0px;
    width: 220px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    font-size: 12px;
}   

#down {
    border-radius: 10px 0 0 10px;
}
#up {
    border-radius: 0 10px 10px 0;
}

#down, #up {
  background: #6d76f7;
  width: 110px;
  padding: 14px 0;
  transition: 0.3s;
  position: relative;
  text-align: center;
}
#down:hover, #up:hover {
    background: #4e54b1;
}

#down span {
  transform: translateY(-50%) rotate(90deg);
  right: 2px;
}
#up span {
  transform: translateY(-50%) rotate(-90deg);
  left: 4px;
}
#down span, #up span {
  position: absolute;
  top: 50%;
}


.box-filter{
    display: none;
    height: 550px;
    width: 400px;
    flex: 1;
    margin: 40px auto 20px auto;
    padding: 20px;
    box-shadow: 0px 1px 5px 2px rgba (0,0,0,0.75);
    -webkit-box-shadow: 0px 1px 5px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 5px 2px rgba(0,0,0,0.75);
}

.open{
    display: block;
}

.filter-container-button{
    display: flex;
    justify-content: space-around;
}

.button-filter{
    min-width: 120px;
}

@media screen and (max-width: 1450px) {
    .page-container{
        flex-direction: column;
    }
}
