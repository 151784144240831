li{
    list-style: none;
}


.informations{
    background-color: #6d76f7;
    width: 50%;
    height: 400px;
    margin: 100px auto;
    border-radius: 25px;
    box-shadow: 10px 10px 5px 0px rgba (0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.informations-container{
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.informations-title{
    color: #fff;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
}

.informations-data{
    color: #fff;
    padding-left: 5px;
}

.informations-label{
    align-self: flex-start;
    padding-left: 80px;
    margin-bottom: 10px;
}

.informations-technicien{
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: khaki;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.informations-technicien h2{
    color: black;
}

.informations-technicien-icon{
    width: 200px;
    height: 200px;
}

.logout{
    text-align: center;
}

.logout-button{
    background-color: #fff;
    color: #6d76f7;
    font-weight: 600;
    padding: 5px 16px;
    border: none;
    border-radius: 16px;
    font-size: 1.5rem;
    transition: 0.3s all ease-in-out;
}

.logout-button:hover{
    transform: scale(1.1);
    cursor: pointer;
}

@media screen and (max-width:960px) {
    .informations-technicien{
        display: none;
    }
}