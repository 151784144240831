a{
    text-decoration: none;
    color: #fff;
}

.navBar-login{
    background: rgb(90, 85, 194);
    height: 130px;
    width: 100%;
    font-size: 1.2rem;
    color: #fff;
}

.navBar-login-center{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
}

.navBar-login-title, .navBar-login-title img{
    transition: 0.3s all;
}

.navBar-login-title:hover{
    transform: scale(1.05);
}

.navBar-login-title:hover img{
    transform: scale(1.1);
}

.navBar-login ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

@media screen and (max-width:960px) {
    .navBar-login{
        height: 100vh;
        width: 20%;
        font-size: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .navBar-login-title, .navBar-login-title img{
        width: 130px;
        height: 80px
    }

    .navBar-login ul{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: space-around;
        gap: 20px;
    }

    .navBar-login-center{
        justify-content: center;
        align-items: flex-start;
        padding-left: 10px;
    }
}