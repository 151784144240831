.box-create{
    height: 650px;
    position: relative;
}

.creation-form{
    padding-top: 20px;
    display: flex;
    height: 80%;
    justify-content: space-around;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
}
.right{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.column{
    display: flex;
}

.input-create{
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid grey;
    padding: 8px 4px;
    width: 100%;
    min-width: 100px;
}

input[type="radio"]{
    margin: 8px 10px;
}

textarea{
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid black;
    background-color: #f5f5f5;
    padding: 8px 4px;
    width: 100%;
    min-width: 100px;
    height: 210px;
    transition: 0.3s all;
}

textarea:focus{
    border-bottom: 2px solid #6d76f7;
    outline: none;
}

input:focus{
    border-bottom: 2px solid #6d76f7;
    outline: none;
}

.filterBlock{
    position: relative;
}

.filterBlock ul {
    display: none;
    z-index: 3;
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    margin-top: 4px;
    padding: 0 8px;
    max-height: 320px;
    background-color: #ffffff;
  }

.filterBlock ul.activeSite, .filterBlock ul.activeUser{
    display: block;
}
  
.filterBlock ul li {
    padding: 4px;
    background: white;
    border-bottom: 1px solid #c7c0c0;
    margin-top: 0.5px;
    cursor: pointer;
}
  
.filterBlock li:hover {
    color: violet;
}

.input-container{
    margin: 15px;
}

.input-container .label{
    color: rgba(0,0,0,.54);
    font-size: 11px;
}

.radio{
    margin: 8px;
    display: block;
    cursor: pointer;
    user-select:none;
    text-align: left;
}

.radio span{
    font-size: 15px;
}

.bottom-button{
    margin: 10px  0 0 30px;
    width: 15%;
}

input[type="date"].error{
    border-bottom: 1px solid rgb(220, 67, 67);
}

.error{
    border-bottom: 1px solid rgb(220, 67, 67);
}
.error+span{
    color:rgb(220, 67, 67);
}

.error::placeholder{
    color: rgb(220, 67, 67);
}

@media screen and (max-width: 1450px) {
    .page-container{
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .box-create{
        height: 1800px;
        text-align: center;
    }
    .box-create ul{
        width: 50%;
        margin: 0 auto;
    }
    .creation-form{
        height: 90%;
        flex-direction: column;
    }
    .left{
        align-items: center;
    }
    .right{
        justify-content: center;
    }
    .column{
        flex-direction: column;
    }
}