.register{
    background-color: #6d76f7;
    width: 50%;
    margin: 30px auto;
    padding: 50px;
    border-radius: 25px;
    box-shadow: 10px 10px 5px 0px rgba (0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    text-align: center;
}

.register-title{
    color: white;
    padding: 5px;
    width: 100%;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
}

.register-form{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    gap: 4px;
}

.register-form-un, .register-form-deux{
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: flex-start
}

.select-input{
    background-size: 12px;
    background-position: 15px center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    padding: 10px 5px 10px 20px;
    text-indent: 22px;
    width: 39%;
    min-width: 200px;
}

@media screen and (max-width:960px) {
    .register-form{
        display: flex;
        flex-direction: column;
        padding: 20px;
    }
}