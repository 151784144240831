.login-container{
    background-color: #6d76f7;
    width: 50%;
    height: 450px;
    margin: 80px auto;
    border-radius: 25px;
    box-shadow: 10px 10px 5px 0px rgba (0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.login{
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.login-title{
    color: white;
    padding: 5px;
    width: 100%;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
}

.login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.login-input{
    background-size: 12px;
    background-position: 15px center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 33px;
    padding: 10px 5px 10px 20px;
    text-indent: 22px;
    width: 40%;
    min-width: 200px;
}

.login-username{
    background-image: url(../assets/mail.svg);
}

.login-password{
    background-image: url(../assets/password.svg);
}

.button-submit{
    font-size: 17px;
    text-align: center;
    color: white;
    background-color: #303132;
    border: 0px;
    border-radius: 33px;
    padding: 10px 20px;
    width: 40%;
    min-width: 200px;
    display: inline-block;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
  }

.button-submit:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #1f2021;
    transition: all .3s;
    border-radius: 33px;
    z-index: -1;
  }
  
.button-submit:hover:before {
    width: 100%;
  }

.login-register{
    text-align: center;
}

.login-register span{
    margin-left: 6px;
}

.login-register-link{
    color: white;
    text-decoration: none;
    transition: 0.2s all ease;
}

.login-register-link:hover{
    color: rgb(232, 226, 226);
    text-decoration: underline;
}

.login-technicien{
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: khaki;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.login-technicien-icon{
    width: 200px;
    height: 200px;
}

@media screen and (max-width:960px) {
    .login-technicien{
        display: none;
    }
}